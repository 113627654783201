import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic11 from '../assets/images/pic11.jpg'

const Success = props => (
  <Layout>
    <Helmet>
      <title>Thank You - Horovatin Consulting Ltd.</title>
      <meta name="description" content="Thank you for your input." />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Thank You</h1>
          </header>
          <span className="image main">
            <img
              src={pic11}
              alt="View over the Alberta Badlands showing a big blue sky with light puffy clouds"
            />
          </span>
          <p>Your message has been submitted.</p>
          <p>
            If necessary, we will respond within five business days. If your
            request is more urgent please email or phone us.
          </p>
        </div>
      </section>
    </div>
  </Layout>
)

export default Success
